export default function Quality () {
    let quality =
        <>
            <div className={"content-wrapper"}>
                <div className={"content"}>
                    <h1>
                        Quality
                    </h1>
                    <p>
                        The results of our quality program that is based on
                        <a href={"https://www.iso.org/iso-9001-quality-management.html"}> ISO9000 Standards</a>,
                        <a href={"http://everyspec.com/MIL-SPECS/MIL-SPECS-MIL-I/MIL-I-45208A_2885/"}> MIL-I-45208</a>,
                        <a href={"http://everyspec.com/MIL-STD/MIL-STD-2000-2999/MIL-STD-2000_10934/"}>MIL-2000</a>,
                        and <a href={"https://www.ipc.org/ipc-610-acceptability-electronics-assemblies-endorsement-program"}> IPC-A-610 </a>
                        specifications are evidenced by numerous customer letters of appreciation, awards, and consistent
                        high quality acceptance rates has proven that careful planning, thorough testing and excellent
                        workmanship is key in producing a quality product. Our philosophy is quality must be built into
                        the product and cannot be inspected or tested into the product. Contrax provides black box testing
                        through GenRad in-circuit testing that provides customers with zero defects. Our quality manual is
                        available for review by our customers at their request. We view each customer as a very important
                        member of our quality assurance team and your continued satisfaction is our greatest measure of our
                        quality success. Our Quality Control department is flexible enough to respond to modifications that
                        meet our customer’s requirements. Visual inspection under state of the art microscope. Inspection
                        of material being received.
                    </p>
                </div>
            </div>
        </>;

    return quality
}