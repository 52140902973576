import toggleMenu from "./Scripts/toggleMenu";
import menuButton from "./Scripts/Assets/dropdown.svg";
import {Link} from "react-router-dom";
import logo from "./Scripts/Assets/contrax_logo.svg";
import search from "./Scripts/Assets/Search.svg";

export default function  Nav ()
{
    let nav =
    <>
        <nav role="navigation">
            <div className="nav-container">
                <div className="nav-inner navbar-items">
                    <div className="dropdown-menu">
                        <button className="small-menu-button navbar-items" id="small-menu-button" onClick={toggleMenu}>
                            <img src={menuButton} className="small-menu-button-size" id={"small-menu-button-ico"}/>
                        </button>
                    </div>

                    <Link to={"/"} className="navbar-brand">
                        <div className="navbar-items logo-image nav-space">
                            <img src={logo} className="logo-img-size" id={"logo-img"} alt={"Contrax Technologies"}>
                            </img>
                        </div>
                    </Link>
                    <ul className={"nav-links nav-space"}>
                        <li className="nav-left nav-first">
                            <Link to={"/services"}>Services</Link>
                        </li>

                        <li className="nav-left">
                            <Link to={"/capabilities"}>Capabilities</Link>
                        </li>

                        <li className="nav-left">
                            <Link to={"/quality"}>Quality</Link>
                        </li>

                        <li className="nav-left">
                            <Link to={"/about"}>About</Link>
                        </li>
                        <li className="nav-left">
                            <Link to={"/contact"}>Contact</Link>
                        </li>
                    </ul>
                    <a className="search nav-right navbar-items">
                        <div className="nav-search-asset">
                            <img src={search}/>
                        </div>
                    </a>
                </div>
            </div>
            <ul className="dropdown-menu menu-content" id="menu-content">
                <li className="links-hidden">
                    <Link to={"/services"}>Services</Link>
                </li>

                <li className="links-hidden">
                    <Link to={"/capabilities"}>Capabilities</Link>
                </li>

                <li className="links-hidden">
                    <Link to={"/quality"}>Quality</Link>
                </li>

                <li className="links-hidden">
                    <Link to={"/about"}>About</Link>
                </li>
                <li className="links-hidden">
                    <Link to={"/contact"}>Contact</Link>
                </li>
            </ul>
        </nav>
        <div id={"black-tint"}></div>
    </>;
    return nav
}