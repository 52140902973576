import React, { useEffect, useState } from 'react';


export default function  Services () {
    const [headings, setHeadings] = useState([]);

    // Fetch headings once the component mounts
    useEffect(() => {
        const fetchHeadings = () => {
            const headings = Array.from(document.querySelectorAll('.content-wrapper h1'));
            setHeadings(headings);
        };

        fetchHeadings(); // Initial fetch

        // Clean-up function to prevent memory leaks
        return () => {
            // Cleanup logic here (if needed)
        };
    }, []);

    // Fix the capitalization for lazing coding
    function capitalizeFirstLetter(string) {
        return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    return (
        <>
            <div className={"content-wrapper"}>
                <div className={"content"}>

                    <div className="upper-level" style={{display: 'flex', paddingTop: '10%'}}>
                        <div style={{width: '30%'}}>
                            <div className="navigation">
                                <h3>
                                    Offered Services
                                </h3>
                                <ul>
                                    {headings.map((heading, index) => (
                                        <li key={index}>
                                            <a href={`#${heading.id}`} style={{color:"#91233b"}}>{capitalizeFirstLetter(heading.textContent)}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div style={{width: '70%'}}>
                            <p style={{fontSize: "medium"}}>
                                CTI offers a range of services tailored to your needs. Our Design, Engineering, and Test
                                Development & Implementation services ensure product excellence. We excel in Logistics,
                                streamlining your supply chain for efficient delivery. CTI's New Product Introduction
                                (NPI) accelerates time-to-market, while our Custom Material Solutions meet unique
                                requirements. We're committed to exceeding expectations and propelling your success.
                            </p>
                        </div>
                    </div>
                    <hr style={{
                        width: '50%',
                        height: '0px',
                        margin: '0 auto',
                        border: 'none',
                        borderBottom: '2px solid #91233b',
                        paddingTop: '100px',
                        marginBottom: '50px'
                    }}/>
                    <h1 id="design section">
                        Design Services
                    </h1>
                    <div className={"service first-content"}>
                        <div className={"headings"}>
                            <h2>ELECTRO-MECHANICAL ASSEMBLY
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Efficiently integrate electronic and mechanical components into complex assemblies with
                                our electro-mechanical assembly services. From prototype to production, we ensure
                                precise assembly, testing, and quality control, delivering reliable solutions for
                                diverse applications. Partner with us for seamless integration and superior performance
                                in your electro-mechanical systems.

                            </p>
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>SUB-ASSEMBLIES AND FINAL PRODUCT
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Accelerate your production process with our sub-assembly and final product solutions. We
                                meticulously assemble components into sub-units and integrate them into finished
                                products, ensuring quality and efficiency at every stage. Trust us for seamless
                                coordination and reliable delivery of sub-assemblies and final products, tailored to
                                your specifications. </p>
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>NPI AND PRODUCT VOLUMES
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Navigate the product development lifecycle seamlessly with our New Product Introduction
                                (NPI) and scalable production volume solutions. From initial prototyping to full-scale
                                manufacturing, we offer expertise in managing product transitions, ensuring quality and
                                efficiency at every stage. Partner with us for smooth NPI processes and flexible
                                production volumes tailored to your project needs.
                            </p>
                        </div>
                    </div>
                    <h1 id="engineering-section">
                        Engineering Services

                    </h1>
                    <div className={"service first-content"}>
                        <div className={"headings"}>
                            <h2>DESIGN FOR TEST (DFT)
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Ensure product reliability and manufacturability with our Design for Test (DFT)
                                solutions. We optimize circuit designs to facilitate efficient testing and debugging
                                processes during product development. By incorporating testability features early in the
                                design phase, we minimize time-to-market and enhance product quality. </p>
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>DESIGN FOR SUPPLY CHAIN (DFSC)
                            </h2>
                        </div>
                        <div className={"information"}>
                            Optimize your supply chain efficiency with our Design for Supply Chain (DFSC) services. We
                            integrate supply chain considerations into product design, ensuring seamless material
                            sourcing, logistics, and manufacturing processes. By proactively addressing supply chain
                            challenges during the design phase, we minimize costs, reduce lead times, and enhance
                            overall supply chain performance.
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>DESIGN FOR MANUFACTURABILITY (DFM)
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Enhance production efficiency and product quality with our Design for Manufacturability
                                (DFM) services. Our expert team optimizes product designs to simplify manufacturing
                                processes, reduce production costs, and improve time-to-market. By considering
                                manufacturability early in the design phase, we mitigate risks and ensure seamless
                                transition to mass production. </p>
                        </div>
                    </div>
                    <h1 id="testing-section">
                        Test Development & Implementation

                    </h1>
                    <div className={"service first-content"}>
                        <div className={"headings"}>
                            <h2>FLYING PROBE (FP)
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Achieve efficient and accurate electronic testing with our Flying Probe (FP) services.
                                Our state-of-the-art equipment swiftly navigates circuits, performing non-invasive tests
                                on printed circuit boards (PCBs) without the need for custom fixtures. From prototype
                                validation to production testing, we offer rapid, cost-effective solutions for
                                identifying defects and ensuring product quality.

                            </p>
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>IN-CIRCUIT TESTING (ICT)
                            </h2>
                        </div>
                        <div className={"information"}>
                            Ensure the integrity of your electronic assemblies with our In-Circuit Testing (ICT)
                            solutions. Using advanced test fixtures and probes, we verify component placement and
                            functionality, detecting faults such as shorts, opens, and incorrect components. With our
                            comprehensive ICT services, we provide thorough testing coverage to validate the electrical
                            performance and reliability of your PCB assemblies.


                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>FUNCTIONAL TESTING
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Validate the functionality and performance of your products with our Functional Testing
                                services. Our comprehensive testing procedures assess product behavior under real-world
                                conditions, ensuring adherence to specifications and customer requirements. From user
                                interface testing to system integration, we provide rigorous testing methodologies to
                                verify product functionality and reliability, delivering superior quality and customer
                                satisfaction.

                            </p>
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>BOUNDARY SCAN
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Enhance test coverage and fault detection capabilities with our Boundary Scan services.
                                Using IEEE 1149.1 standard-compliant techniques, we access and test digital circuitry
                                embedded within integrated circuits (ICs), identifying faults such as shorts, opens, and
                                missing components. With our Boundary Scan solutions, we offer efficient and
                                cost-effective testing for complex electronic assemblies, ensuring product quality and
                                reliability.

                            </p>
                        </div>
                    </div>
                    <h1 id="logisitcs-section">
                        Logistic Services
                    </h1>
                    <div className={"service first-content"}>
                        <div className={"headings"}>
                            <h2>
                                DIRECT ORDER FULFILLMENT
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Streamline your order processing and delivery with our Direct Order Fulfillment
                                services. From order capture to shipment, we handle the entire fulfillment process
                                efficiently and accurately, ensuring timely delivery to your customers. With our
                                integrated systems and logistics expertise, we optimize inventory management and reduce
                                order cycle times, enhancing customer satisfaction and loyalty.

                            </p>
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>
                                THIRD PARTY LOGISTICS
                            </h2>
                        </div>
                        <div className={"information"}>
                            Outsource your logistics operations to our Third-Party Logistics services for cost-effective
                            and scalable solutions. We provide end-to-end logistics support, including warehousing,
                            transportation, and distribution, tailored to your specific needs. With our extensive
                            network and expertise, we optimize supply chain efficiency, reduce overhead costs, and
                            improve overall operational performance.


                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>
                                Inventory Management
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Optimize your inventory levels and control costs with our Inventory Management
                                solutions. Our advanced inventory tracking systems and analytical tools enable real-time
                                visibility and control over inventory levels, ensuring optimal stock levels and
                                minimizing excess or obsolete inventory. With our proactive approach to inventory
                                management, we help you improve cash flow, reduce carrying costs, and enhance
                                operational efficiency.

                            </p>
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>
                                Aftermarket Repair and Support
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Ensure customer satisfaction and product reliability with our Aftermarket Repair and
                                Support services. We offer comprehensive repair and maintenance solutions for your
                                products, including warranty repairs, refurbishment, and technical support. With our
                                dedicated team of experts and efficient repair processes, we minimize downtime and
                                extend the lifecycle of your products.

                            </p>
                        </div>
                    </div>
                    <h1 id="npi-section">
                        new product introduction (NPI)
                    </h1>
                    <div className={"service first-content"}>
                        <div className={"headings"}>
                            <h2>
                                Rapid Prototyping
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Accelerate your product development cycle with our Rapid Prototyping services. We
                                utilize cutting-edge technologies and agile methodologies to quickly produce functional
                                prototypes, allowing for rapid iteration and validation of design concepts. From concept
                                to prototype, we help you bring your ideas to life faster and more cost-effectively,
                                reducing time-to-market and increasing innovation.

                            </p>
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>
                                Design for Manufacturing / <br/> Design for Test
                            </h2>
                        </div>
                        <div className={"information"}>
                            Optimize your product design for manufacturability and testability with our Design for
                            Manufacturing (DFM) and Design for Test (DFT) services. We analyze and enhance your product
                            design to simplify manufacturing processes, reduce production costs, and improve test
                            coverage and efficiency. By addressing manufacturing and testing considerations early in the
                            design phase, we ensure seamless transition to mass production and minimize risks throughout
                            the product lifecycle.


                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>
                                Bill of Materials Review / <br/> Analysis
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Ensure accuracy and efficiency in your supply chain with our Bill of Materials (BOM)
                                Review and Analysis services. Our expert team conducts thorough reviews and analyses of
                                your BOMs to identify potential issues, such as component availability, cost
                                optimization opportunities, and compliance requirements. With our detailed insights and
                                recommendations, we help you optimize your BOMs, streamline procurement processes, and
                                minimize supply chain risks.

                            </p>
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>
                                Final System Integration and Test Development
                            </h2>
                        </div>
                        <div className={"information"}>
                            <p>
                                Ensure product reliability and performance with our Final System Integration and Test
                                Development services. We manage the integration of subsystems and components into the
                                final product, conducting comprehensive testing and validation to verify functionality
                                and reliability. From system integration to environmental testing, we ensure that your
                                products meet quality standards and customer requirements, enabling successful product
                                launches and customer satisfaction.

                            </p>
                        </div>
                    </div>
                    <h1 id="material section">
                        custom material solutions
                    </h1>
                    <div className={"service first-content"}>
                        <div className={"headings"}>
                            <h2>
                                Material Solutions
                            </h2>
                        </div>
                        <div className={"information"}>
                            PCB, Electro-mechanical, Metals, Plastics
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}