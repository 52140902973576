import exit from "./Assets/exit.svg";
import menuButton from "./Assets/dropdown.svg";


export default function toggleMenu()
{
	const button = document.querySelector('.small-menu-button')

	button.onclick = () => {
		if (document.getElementById('menu-content').style.visibility === 'hidden')
		{
			// After click actions
			document.getElementById('menu-content').style.visibility = 'visible';
			document.getElementById('black-tint').style.visibility = 'visible';
			document.getElementById('small-menu-button-ico').src = exit;
			document.getElementById('small-menu-button').style.backgroundColor = 'grey';
			document.getElementById('small-menu-button-ico').style.width = '10px';
			document.getElementById('small-menu-button').style.padding = '25px';

		} else 
		{
			document.getElementById('menu-content').style.visibility = 'hidden';
			document.getElementById('black-tint').style.visibility = 'hidden';
			document.getElementById('small-menu-button-ico').src = menuButton;
			document.getElementById('small-menu-button').style.backgroundColor = 'white'
			document.getElementById('small-menu-button-ico').style.width = '20px';
			document.getElementById('small-menu-button').style.padding = '20px';

		}
	};
}