import React, { Component } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix the issue with the marker images not found
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export class LeafletMap extends Component {
    componentDidMount() {
        // Create the map
        this.map = L.map('map', {
            center: [51.505, -0.09], // Coordinates for the initial map center
            zoom: 13, // Initial zoom level
        });

        // Add the tile layer to the map
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        // Add a marker to the map
        L.marker([51.505, -0.09]).addTo(this.map)
            .bindPopup('A pretty CSS3 popup.<br> Easily customizable.')
            .openPopup();
    }

    render() {
        return <div id="map" style={{ height: '400px', width: '400px' }}></div>;
    }
}