import React from "react";
import Helmet from "react-helmet";

export default function Footer () {
    let footer =
        <>
            <Helmet>
                <script type={"application/javascript"}>
                    document.getElementById("year").innerHTML = new Date().getFullYear();
                </script>
            </Helmet>
            <footer>
                <div className={"footer"}>
                    <span className={"foot-left"}> All Rights reserved © <span id={"year"}></span> Contrax Technologies, Inc.
                    </span>
                </div>
            </footer>
        </>;

    return footer
}