export default function About () {
    let about =
        <>
            <div className={"content-wrapper"}>
                <div className={"content land"}>
                    <h1>
                        About
                    </h1>
                    <p>
                        Since being founded in 1987, Contrax Technologies has grown from 1500 square feet to 12,000 square
                        feet and is looking to expand further in the near future.
                        Contrax Technologies is a privately owned, independent electronics based, full service technology
                        company specializing on printed circuit board surface mount/through-hole technology, cable and
                        chassis assembly, computer integration, and in-circuit and functional testing services for both
                        the commercial and government markets. Contrax is a sales driven organization that emphasizes
                        assurance and customer satisfaction. Nothing is too small or too big for Contrax – we do small
                        to large production runs and quick turnaround.
                        Our talented management and technical staff have progressively evolved, which assures continuing
                        strength and growth within the company.
                        We possess diverse capabilities. Our scientific and technical skills combined with a progressive
                        quality assurance program have kept us ahead in this rapidly changing and highly competitive industry.
                        We also offer design/engineering services for those companies who require it. We at Contrax are
                        constantly on the move.
                        There are several distinguishing factors that contribute to our successful standing standing as
                        a reliable supplier.
                    </p>
                </div>
            </div>
        </>;

    return about
}