import lwreath from "./Scripts/Assets/Laurel Wreath Left.svg"
import rwreath from "./Scripts/Assets/Laurel Wreath Right.svg"

export default function Home() {
	return(
			<body>
			<div className="wrapper">
				<div className={"content-wrapper"}>
					<div className="landing">
						<div className={"landing-head first-content"} id={"content"}>
							<h1>Over 35 Years of Quality Service!</h1>
						</div>
						<div className={"landing-catch"}>
							<div className={"inner-catch"}>
									<span id={"catch-align"}>
										<img src={lwreath} alt={"Laurel Wreath"} className={"wreath"}/>
										<p className={"text"}>
											Contrax Technologies, Inc. is an accredited award-winning contracting firm. With multiple recognitions that reflect quality service and craftsmanship. Contrax has been serving both the private sector and government since 1987.
										</p>
										<img src={rwreath} alt={"Laurel Wreath"} className={"wreath"}/>
									</span>
							</div>
						</div>
						{/* eslint-disable-next-line jsx-a11y/heading-has-content */}
						<h2>

						</h2>
						<div id={"testimonials"}>
							<div id={'testimonial-container'}>
									<span id={"testimonial"}>
										Our company has been very pleased with your exemplary quality products, on time deliveries and competitive prices. Your friendly customer service is excellent.
									</span>
								<span id={"name"}>
										{/*Name*/}
									</span>
								<span id={"company"}>
										{/*Company Logo*/}
									</span>
							</div>
							<div id={'testimonial-container'}>
									<span id={"testimonial"}>
										We have used Contrax Technologies for over 28 years. They supply us with a very high quality Printed Circuit board assemblies at a very competitive price. Give them a try and you will have an assembly house for consignment and turnkey for life.
									</span>
								<span id={"name"}>

									</span>
								<span id={"company"}>
										<img src={"https://www.aiv-inc.com/web/image/website/1/logo/AIV%20Inc?unique=3fba0a4"} alt={"AIV Logo"}></img>
									</span>
							</div>
							<div id={'testimonial-container'}>
									<span id={"testimonial"}>
										You provide Quality products, outstanding deliveries at a competitive price including excellent customer service. Your Engineering support is a real plus for us.
									</span>
								<span id={"name"}>
										{/*Name*/}
									</span>
								<span id={"company"}>
										{/*Company Logo*/}
									</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg"></div>
			</body>
	);
}