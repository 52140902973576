export default function  Capabilities () {
    let capability =
        <>
            <div className={"content-wrapper"}>
                <div className={"content"}>
                    <h1>
                        capabilities
                    </h1>
                    {/*
                        Overview of Capabilities offered by CTI
                    */}
                    {/*<div style={{width: '75%', margin: '0 auto'}}>*/}
                        <p style={{ }}>
                            At Contrax Technologies, we pride ourselves on our diverse range of capabilities that empower us to
                            deliver exceptional solutions to our clients. With our team of skilled professionals and
                            innovative approach, we strive to exceed expectations and drive success in every project we
                            undertake.
                        </p>
                    {/*</div>*/}

                    <div className={"service first-content"}>
                        <div className={"headings"}>
                            <h2>SMT and thru-hole technology
                            </h2>
                        </div>
                        <div className={"information"}>
                            Surface Mount Technology (SMT) and Through-Hole Technology is one of our cutting-edge
                            electronics assembly capability. From compact, high-performance SMT components to robust and
                            reliable Through-Hole assemblies, we offer versatile solutions tailored to your unique
                            needs. Partner with us for seamless integration and superior quality in electronic
                            manufacturing.
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>
                                Small and large quantities
                            </h2>
                        </div>
                        <div className={"information"}>
                            At Contrax, we specialize in delivering high-quality products regardless of order size.
                            Whether you need a small batch for prototyping or large-scale production runs, we provide
                            efficient and reliable solutions tailored to your requirements. Partner with us for seamless
                            production and timely delivery, no matter the quantity.
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>
                                On-Time Assurance
                            </h2>
                        </div>
                        <div className={"information"}>
                            Experience peace of mind with our On-Time Assurance guarantee. At Contrax, we prioritize
                            punctuality and reliability in every project we undertake. With our commitment to meeting
                            deadlines, you can trust us to deliver your products or services on time, every time.
                            Partner with us for dependable performance and on-time delivery, ensuring your projects stay
                            on schedule and on track for success.
                        </div>
                    </div>
                    <div className={"service"}>
                        <div className={"headings"}>
                            <h2>
                                One day turnaround
                            </h2>
                        </div>
                        <div className={"information"}>
                            We understand the importance of speed and agility in product development. Our streamlined
                            processes and efficient workflow enable us to quickly turn your ideas into tangible
                            prototypes and small batches. Partner with us for swift turnaround times and accelerate your
                            path to market.
                        </div>
                    </div>
                </div>
            </div>
        </>;

    return capability
}